.popupSize {
  padding: 0px 15px;
  max-height: 400px;
  overflow: auto;

  & fieldset {
    border: none;
  }
}

.popupSearchBar {
  padding: 17px 15px 10px;
}

.popupSearchBarInput {
  padding: 0px 7px;
}

.indent {
  padding-left: 20px;
}

.popupSearchBarLabel {
  display: block;
  margin-bottom: 10px;
}
